<template>
  <v-app>
    <app-toolbar v-if="isUserLoggedIn" />
    <!-- Global Modals -->
    <app-snackbar />
    <app-deleteModal />
    <app-alerts v-if="$root.alert.status" />
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
    <!-- Resource Picker -->
    <v-dialog
      v-if="$root.resourcePicker.status"
      v-model="$root.resourcePicker.status"
      fullscreen
      persistent
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar flat dark>
          <v-spacer></v-spacer>
          <v-btn @click="$root.resourcePicker.status = false" fab icon
            ><v-icon>{{mdiClose}}</v-icon></v-btn
          >
        </v-toolbar>
        <br />
        <unoapp-resource-picker
          @resourcesSubmitted="resourceSelected($event)"
          :auth-token="vg_user.token"
        />
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiClose } from '@mdi/js'
export default {
  name: 'App',
  components: {
    AppToolbar: () => import('@/components/Toolbar.vue'),
    AppAlerts: () => import('@/components/alerts/Alerts.vue'),
    AppSnackbar: () => import('@/components/UI/Snackbar.vue'),
    AppDeleteModal: () => import('@/components/UI/DeleteModal.vue')
  },

  data: () => ({
    mdiClose
    //
  }),
  computed: {
    ...mapGetters({
      vg_user: 'auth/user'
    }),
    isUserLoggedIn() {
      return this.$route.name !== 'Login'
    }
  },
  methods: {
    resourceSelected(e) {
      console.log(e)
      this.$root.resourcePicker.status = false
      this.$root.$emit('resource-selected-event', e.detail)
    }
  }
}
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:100,300,400,500,700,900");
$body-font-family: "Roboto";
$title-font-family: "Open Sans";
$skeleton-loader-avatar-width: 125px;
.v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font-family, sans-serif !important;
  }
  .v-skeleton-loader__avatar {
    height: $skeleton-loader-avatar-width;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
