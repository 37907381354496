
const SET_SNACKBAR_TIMEOUT = 'SET_SNACKBAR_TIMEOUT'
const SET_SNACKBAR_TEXT = 'SET_SNACKBAR_TEXT'
const SET_SNACKBAR_OPEN = 'SET_SNACKBAR_OPEN'
const SET_SNACKBAR_COLOR = 'SET_SNACKBAR_COLOR'
const SET_SNACKBAR_X = 'SET_SNACKBAR_X'
const SET_SNACKBAR_Y = 'SET_SNACKBAR_Y'
const SET_SNACKBAR_ICON = 'SET_SNACKBAR_ICON'

const state = {
  open: false,
  text: '',
  timeout: 0,
  x: null,
  y: 'bottom',
  color: null,
  icon: null
}

export const getters = {
  open: state => state.open,
  text: state => state.text,
  timeout: state => state.timeout,
  x: state => state.x,
  y: state => state.y,
  color: state => state.color,
  icon: state => state.icon
}

export const actions = {
  setOpen({ commit }, open) {
    commit(SET_SNACKBAR_OPEN, open)
  },
  setText({ commit }, text) {
    commit(SET_SNACKBAR_TEXT, text)
  },
  snackbar({ commit }, { text, open, timeout, color, x, y, icon }) {
    if (timeout !== undefined) {
      commit(SET_SNACKBAR_TIMEOUT, timeout)
    } else {
      commit(SET_SNACKBAR_TIMEOUT, 0)
    }
    commit(SET_SNACKBAR_OPEN, open)
    commit(SET_SNACKBAR_TEXT, text)
    if (color) {
      commit(SET_SNACKBAR_COLOR, color)
    } else {
      commit(SET_SNACKBAR_COLOR, null)
    }
    if (x) {
      commit(SET_SNACKBAR_X, x)
    } else {
      commit(SET_SNACKBAR_X, null)
    }
    if (y) {
      commit(SET_SNACKBAR_Y, y)
    } else {
      commit(SET_SNACKBAR_Y, 'bottom')
    }
    if (icon) {
      commit(SET_SNACKBAR_ICON, icon)
    } else {
      commit(SET_SNACKBAR_ICON, null)
    }
  }
}

export const mutations = {
  [SET_SNACKBAR_OPEN](state, open) {
    state.open = open
  },
  [SET_SNACKBAR_TEXT](state, text) {
    state.text = text
  },
  [SET_SNACKBAR_TIMEOUT](state, miliseconds) {
    state.timeout = miliseconds
  },
  [SET_SNACKBAR_COLOR](state, color) {
    state.color = color
  },
  [SET_SNACKBAR_X](state, x) {
    state.x = x
  },
  [SET_SNACKBAR_Y](state, y) {
    state.y = y
  },
  [SET_SNACKBAR_ICON](state, icon) {
    state.icon = icon
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
