import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '../node_modules/@mdi/font/css/materialdesignicons.min.css'
import GeneralMixin from '@/utils/mixins/General'
import MiddlewarePlugin from 'vue-router-middleware-plugin'
import { defineCustomElements } from '@/components/stencil/unoapp-components.stencil.js'
import { applyPolyfills as formsPolyfills, defineCustomElements as UnoappForms } from '@unoapp/forms-engine-component/loader'
import VueApexCharts from 'vue-apexcharts'
formsPolyfills().then(() => {
  UnoappForms()
})
// AIzaSyBJs2f0TNNui_OcRHgVRTIsJAvGQ0EB7oA

Vue.mixin(GeneralMixin)
Vue.use(MiddlewarePlugin, router)
Vue.config.productionTip = false
defineCustomElements()
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
new Vue({
  data: () => ({
    toggleMini: true,
    leftNav: true,
    manageCampaign: {
      leftNav: false
    },
    alert: {
      status: false,
      type: 'success',
      title: '',
      text: '',
      callback: null
    },
    resourcePicker: {
      status: false,
      resource: null
    },
    deleteModal: {
      status: false,
      title: '',
      description: '',
      callback: null,
      buttonText: '',
      eventName: ''
    },
    addExtraMargin: false
  }),
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
