import store from '@/store'
import { $isBetween, $isSameOrAfter, $isSameOrBefore } from '../datetime'

export default {
  methods: {
    $isBetween,
    $isSameOrAfter,
    $isSameOrBefore,
    $openDeleteModal({ title = '', description = '', eventName = '', callback = null, buttonText = 'Delete' }) {
      this.$root.deleteModal.title = title
      this.$root.deleteModal.description = description
      this.$root.deleteModal.callback = callback
      this.$root.deleteModal.eventName = eventName
      this.$root.deleteModal.buttonText = buttonText
      this.$root.deleteModal.status = true
    },
    $snackbar({ text = '', open = true, timeout = 3000, color = 'grey darken-2', x = null, y = 'bottom', icon = null }) {
      store.dispatch('snackbar/snackbar', { text, open, timeout, color, x, y, icon })
    },
    openResourcePicker() {
      this.$root.resourcePicker.status = true
    },
    spinner(state) {
      this.$root.spinners.main = state
    },
    showSuccessAlert({ title = 'Woo Hoo!', text = '', callback = null }) {
      this.$root.alert.type = 'success'
      this.$root.alert.title = title
      this.$root.alert.text = text
      this.$root.alert.callback = callback
      this.$root.alert.status = true
    },
    showFailureAlert({ title = 'Uh oh.', text = '', callback = null }) {
      this.$root.alert.type = 'error'
      this.$root.alert.title = title
      this.$root.alert.text = text
      this.$root.alert.callback = callback
      this.$root.alert.status = true
    },
    isUserSuperAdmin() {
      return store.getters['auth/user'].type === 'super'
    },
    $timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    $previewContest(link) {
      window.open(link, 'blank')
    }
  },
  computed: {
    contestUrl() {
      return process.env.VUE_APP_CONTEST_URL
    },
    $authUrl() {
      return process.env.VUE_APP_AUTH_API_URL
    },
    $authApiToken() {
      return process.env.VUE_APP_AUTH_API_TOKEN
    }
  }
}
