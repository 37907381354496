import dayjs from 'dayjs'
var isBetween = require('dayjs/plugin/isBetween')
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const $isBetween = function (date1, date2, date3) {
  return dayjs(date1).isBetween(date2, date3)
}

export const $isSameOrBefore = function (date1, date2) {
  return dayjs(date1).isSameOrBefore(date2)
}

export const $isSameOrAfter = function (date1, date2) {
  return dayjs(date1).isSameOrAfter(date2)
}

export const $difference = function (date1, date2, unit) {
  const d1 = dayjs(date1)
  const d2 = dayjs(date2)
  return d1.diff(d2, unit)
}

export const $startOf = function (unit) {
  return dayjs().startOf(unit)
}

export const $endOf = function (unit) {
  return dayjs().endOf(unit)
}

export const $format = function(date, formatString) {
  return dayjs(date).format(formatString)
}
