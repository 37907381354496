import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#252729',
        darkGrey: '#333333',
        secondary: '#424242',
        accent: '#4ABDFE',
        error: '#F67273',
        errorButton: '#C73519',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  }
})
