import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLoggedIn } from './middleware.js'
import { mdiCellphone, mdiCellphoneText, mdiAccountCog } from '@mdi/js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue'),
    meta: {
      nav: {
        icon: '',
        display: false,
        name: 'Login'
      },
      admin: false
    }
  },
  {
    alias: '/',
    path: '/kits',
    name: 'Kits',
    component: () => import(/* webpackChunkName: "about" */ '../views/Client/SelectKit.vue'),
    meta: {
      middleware: isLoggedIn,
      nav: {
        icon: mdiCellphone,
        display: true,
        name: 'Kits'
      },
      admin: false
    }
  },
  {
    path: '/my-campaigns',
    name: 'Campaigns Overview',
    component: () => import(/* webpackChunkName: "about" */ '../views/Client/MyCampaigns.vue'),
    meta: {
      middleware: isLoggedIn,
      nav: {
        display: true,
        name: 'Campaigns Overview',
        icon: mdiCellphoneText
      },
      admin: false
    }
  },
  {
    path: '/results/:id',
    name: 'Result',
    component: () => import(/* webpackChunkName: "about" */ '../views/Client/Report/Results.vue'),
    meta: {
      middleware: isLoggedIn,
      nav: {
        display: false,
        name: 'Campaign Details',
        icon: mdiCellphoneText
      },
      admin: false
    }
  },
  {
    path: '/user/:userid',
    name: 'User Details',
    component: () => import(/* webpackChunkName: "about" */ '../views/Client/Report/UserDetails.vue'),
    meta: {
      middleware: isLoggedIn,
      nav: {
        display: false,
        name: 'Consumer Profile',
        icon: mdiCellphoneText
      },
      admin: false
    }
  },
  {
    path: '/view-kit/:id?',
    name: 'View Kit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Client/KitDetails.vue'),
    meta: {
      middleware: isLoggedIn,
      nav: {
        display: false,
        name: 'View Kit'
      },
      admin: false
    }
  },
  {
    path: '/campaign-requests',
    name: 'Campaign Requests',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/CampaignRequests.vue'),
    meta: {
      middleware: isLoggedIn,
      nav: {
        display: true,
        name: 'Campaign Requests',
        icon: mdiAccountCog
      },
      admin: true
    }
  }
  // {
  //   path: '/manage/:id/request/:req',
  //   name: 'Manage Campaign Request',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign.vue'),
  //   meta: {
  //     middleware: isLoggedIn,
  //     nav: {
  //       display: false,
  //       name: 'Manage Campaign Reqests',
  //       icon: mdiGamepadCircleOutline
  //     },
  //     admin: true
  //   },
  //   children: [{
  //     path: 'status',
  //     name: 'Campaign Status',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignRequestStatus.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Status',
  //         icon: mdiTextBoxCheck
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'details',
  //     name: 'Campaign Details',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignDetails.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Details',
  //         icon: mdiMenu
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'settings',
  //     name: 'Campaign Settings',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignSettings.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Settings',
  //         icon: mdiCog
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'keywords',
  //     name: 'Campaign Keywords',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignKeywords.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Keywords',
  //         icon: mdiKey
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'responses',
  //     name: 'Campaign Responses',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignResponses.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Responses',
  //         icon: mdiCommentQuote
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'tags',
  //     name: 'Campaign Tags',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignTags.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Tags',
  //         icon: mdiPound
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'terms',
  //     name: 'Campaign Terms',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignTerms.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Terms',
  //         icon: mdiAlphabetical
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'branding',
  //     name: 'Campaign Branding',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignBranding.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Branding',
  //         icon: mdiFormatPaint
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'prizing',
  //     name: 'Campaign Prizing',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignPrizes/CampaignPrizing.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Prizing',
  //         icon: mdiGift
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'flow',
  //     name: 'Campaign Flow',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignFlow/CampaignFlow.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Flow',
  //         icon: mdiSitemap
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'badges',
  //     name: 'Campaign Badges',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignBadges.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Badges',
  //         icon: mdiShieldStar
  //       },
  //       admin: true
  //     }
  //   },
  //   {
  //     path: 'benefits',
  //     name: 'Campaign Benefits',
  //     component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ManageCampaign/CampaignBenefits.vue'),
  //     meta: {
  //       nav: {
  //         display: false,
  //         name: 'Campaign Benefits',
  //         icon: mdiAlphaBCircle
  //       },
  //       admin: true
  //     }
  //   }
  //   ]
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
