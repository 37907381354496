const SET_USER = 'SET_USER'
const SET_LOCATIONS = 'SET_LOCATIONS'
const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION'
const SET_BUSINESSES_LIST = 'SET_BUSINESSES_LIST'
const SET_ACTIVE_BUSINESS = 'SET_ACTIVE_BUSINESS'
const SET_ACTIVE_BUSINESS_BRANDING = 'SET_ACTIVE_BUSINESS_BRANDING'
const SET_LOGOUT_STATUS = 'SET_LOGOUT_STATUS'
const SET_BASIC_USER_INFO = 'SET_BASIC_USER_INFO'
const AuthAPI = {}
export default {
  namespaced: true,
  state: {
    user: '',
    locationsList: [],
    selectedLocations: [],
    businessesList: [],
    activeBusiness: '',
    activeBusinessBranding: '',
    loggedOut: true
  },
  getters: {
    logoutStatus: (state) => state.loggedOut,
    user: (state) => state.user,
    locationsList: (state) => state.locationsList,
    selectedLocations: (state) => state.selectedLocations,
    businessesList: state => state.businessesList,
    activeBusiness: state => state.activeBusiness,
    activeBusinessBrandingAndLogo: (state) => {
      if (state.activeBusinessBranding.branding) {
        return {
          primary: state.activeBusinessBranding.branding.primary_color,
          secondary: state.activeBusinessBranding.branding.secondary_color,
          accent: state.activeBusinessBranding.branding.accent_color,
          logo: state.activeBusinessBranding.branding.logo_url === '' ? require('@/assets/images/uno-logo.png') : state.activeBusinessBranding.branding.logo
        }
      }
      return {
        primary: '#F03C0C',
        secondary: '#333333',
        accent: '#ea9780',
        logo: require('@/assets/images/uno-logo.png')
      }
    }
  },
  mutations: {
    [SET_USER](state, payload) {
      state.user = payload
    },
    [SET_LOCATIONS](state, payload) {
      state.locationsList = payload
    },
    [SET_SELECTED_LOCATION](state, payload) {
      state.selectedLocations.push(payload)
    },
    [SET_BUSINESSES_LIST](state, data) {
      state.businessesList = data
    },
    [SET_ACTIVE_BUSINESS](state, business) {
      state.activeBusiness = business
    },
    [SET_ACTIVE_BUSINESS_BRANDING](state, branding) {
      state.activeBusinessBranding = branding
    },
    [SET_BASIC_USER_INFO](state, user) {
      state.user.first_name = user.first_name
      state.user.last_name = user.last_name
      state.user.email = user.email
    },
    [SET_LOGOUT_STATUS](state, logoutStatus) {
      state.loggedOut = logoutStatus
    }
  },
  actions: {
    async loginUser({ commit }, loginData) {
      const { data: { payload } } = await AuthAPI.login(loginData)
      const { user } = payload
      user.token = payload['auth-token']
      commit(SET_USER, user)
      commit(SET_LOGOUT_STATUS, false)
      return payload
    },
    async loginWithToken({ commit }, token) {
      const { data: { payload } } = await AuthAPI.loginWithToken(token)
      const { user } = payload
      user.token = token
      commit(SET_USER, user)
      commit(SET_LOGOUT_STATUS, false)
      return payload
    },
    async setUserBusinessesList({ commit }) {
      const { data: { payload } } = await AuthAPI.getUserBusinesses()
      commit(SET_BUSINESSES_LIST, payload.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
      return payload
    },
    async setUserBusinessesListNotLoggedIn({ commit }, token) {
      const { data: { payload } } = await AuthAPI.getUserBusinessesNotLoggedIn(token)
      commit(SET_BUSINESSES_LIST, payload.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
      return payload
    },
    async setActiveBusiness({ commit }, business) {
      const { data: { payload } } = await AuthAPI.setActiveBusiness(business.id)
      commit(SET_ACTIVE_BUSINESS, business)
      return payload
    },
    async setActiveBusinessBranding({ commit }, businessId) {
      const { data: { payload } } = await AuthAPI.getBusinessById(businessId, true)
      commit(SET_ACTIVE_BUSINESS_BRANDING, payload)
    },

    async setUserLocationList({ commit }) {
      const { data: { payload } } = await AuthAPI.getUserLocations()
      commit(SET_LOCATIONS, payload)
      return payload
    },

    async setUsersforLocation({ commit }, { locationId, page, limit }) {
      const { data: { payload } } = await AuthAPI.getUsersforLoacation(locationId, page, limit)
      return payload
    },

    async setUserToLocation({ commit }, { userId, data }) {
      const payload = await AuthAPI.addUserToLocation(userId, data)
      return payload.data
    },

    async removeUserFromLocation({ commit }, { userId, data }) {
      const payload = await AuthAPI.deleteUserFromLocation(userId, data)
      return payload.data
    }
  }
}
